import React, { useContext, useEffect, useMemo, useState } from 'react';
import { notification } from 'antd';
import { fetchToken, verifyAddress } from 'utils/debank';
import { AccountSummaryDTO } from 'DTO/accountDTO';
import { TokenInterface } from './interface';
import { useAccount } from './AccountProvider';
import { useWallet } from './WalletProvider';

interface IScanProviderProps {
    children: JSX.Element;
}

interface IScanContext {
    address?: string;
    selectedAddress?: string;
    setSelectedAddress?: React.Dispatch<React.SetStateAction<string>>;
    walletTokens?: any;
    walletBalance?: number;
    chainBalance?: any;
}

const ScanContext = React.createContext<IScanContext>(null);

export const useScan = () => useContext(ScanContext);

function ScanProvider({ children }: IScanProviderProps) {
    const { account } = useAccount();
    const { fetchWallet } = useWallet();

    const [address, setAddress] = useState('');
    const [selectedAddress, setSelectedAddress] = useState('');
    const [walletTokens, setWalletTokens] = useState<any>({});
    const [walletBalance, setWalletBalance] = useState(0);
    const [chainBalance, setChainBalance] = useState<any>({});

    const scanAddress = async () => {
        if (!verifyAddress(selectedAddress)) {
            notification.error({
                message: 'Invalid address',
                description: 'Please check the address you entered',
            });
            return;
        }

        const data = await fetchToken(selectedAddress);
        const tokens = data.data
            .filter((one: TokenInterface) => one.price !== 0)
            .sort(
                (a: TokenInterface, b: TokenInterface) =>
                    b.price * b.amount - a.price * a.amount,
            );

        const tokenData: any = {};
        const balance: any = {};

        // eslint-disable-next-line array-callback-return
        tokens.map((token: TokenInterface) => {
            if (tokenData[token.chain] === undefined) {
                tokenData[token.chain] = [];
            }
            tokenData[token.chain].push(token);

            balance[token.chain] =
                (balance[token.chain] || 0) + token.amount * token.price;
        });

        const totalBalance = tokens.reduce(
            (a: number, b: TokenInterface) => a + b.amount * b.price,
            0,
        );

        setWalletBalance(totalBalance);
        setWalletTokens(tokenData);
        setChainBalance(balance);
        setAddress(selectedAddress);

        fetchWallet(account?.account?.AccountId?.toString());
    };

    useEffect(() => {
        if (account?.account?.AccountId)
            fetchWallet(account?.account?.AccountId?.toString());
    }, [account]);

    useEffect(() => {
        if (selectedAddress) scanAddress();
    }, [selectedAddress]);

    const value = useMemo(
        () => ({
            address,
            walletTokens,
            walletBalance,
            chainBalance,
            selectedAddress,
            setSelectedAddress,
        }),
        [address, walletTokens, walletBalance, chainBalance, selectedAddress],
    );

    return (
        <ScanContext.Provider value={value}>{children}</ScanContext.Provider>
    );
}

export default ScanProvider;
